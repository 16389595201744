// Home hero

.page-template-page-php {
  &:before {
    display: none; } }

.home-hero {
  height: 100vh;
  max-height: 100vh;
  padding: 220px 0 50px 0;
  background-size: cover;
  background-position: top;
  position: relative;
  @media only screen and (min-width: $r-768 + 1) {
    background-image: none!important; }
  @media only screen and (max-width: $r-768) {
    padding: 95px 0;
    min-height: 600px;
    background-color: white;
    background-position: center; }
  @media only screen and (max-height: 700px) {
    padding-top: 150px; }
  @media only screen and (max-height: 600px) {
    padding-top: 115px; }
  //&.start
  //  .video
  //    opacity: 1
  //    transition: .8s
  //  .bg
  //    opacity: 0
  //    transition: .8s
  .video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //opacity: 0
    //transition: .8s
    z-index: -2;
    @media only screen and (max-width: $r-768) {
      display: none; }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom; } }
  //.bg
  //  position: absolute
  //  left: 0
  //  top: 0
  //  width: 100%
  //  height: 100%
  //  opacity: 1
  //  transition: .8s
  //  z-index: -1
  //  @media only screen and (max-width: $r-768)
  //    display: none
  //  img
  //    width: 100%
  //    height: 100%
  //    object-fit: cover
  //    object-position: bottom
  .item {
    @media only screen and (max-width: $r-768) {
      text-align: center; }
    h1 {
      max-width: 550px;
      font-style: normal;
      font-weight: 800;
      font-size: 47px;
      line-height: 128.4%;
      text-transform: capitalize;
      margin-bottom: 21px;
      color: #0D142E;
      @media only screen and (max-width: $r-768) {
        font-size: 28px;
        text-align: center;
        max-width: 320px;
        margin: 0 auto 21px auto; }
      b,
      strong {
        font-weight: 800;
        color: $color-violet; } }
    p {
      max-width: 445px;
      color: #444C6A;
      margin-bottom: 37px;
      @media only screen and (max-width: $r-768) {
        max-width: 320px;
        margin: 0 auto 25px; } } } }

// Home text

.home-text {
  background: linear-gradient(89.56deg, #E4F3FF 0.11%, #FFFFFF 101.13%);
  filter: drop-shadow(0px 7px 70px rgba(89, 151, 223, 0.2));
  padding: 30px 0 20px 0;
  @media only screen and (max-width: $r-768) {
    padding: 43px 0; }
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #1D99FF;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 10px; } }
  p {
    max-width: 780px;
    margin: 0 auto 16px auto;
    font-weight: 500;
    text-align: center;
    color: #3E2E64;
    @media only screen and (max-width: $r-768) {
      font-size: 17px;
      line-height: 26.59px; } } }

// Home features

.home-features {
  padding: 140px 0 70px 0;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 60px 0 30px 0; }
  .shape-first {
    position: absolute;
    left: 0;
    top: -160px;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: none; } }
  .shape-second {
    position: absolute;
    right: 0;
    top: 195px;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: none; } }
  .shape-mobile {
    display: none;
    position: absolute;
    left: 0;
    top: -250px;
    width: 100%;
    z-index: -1;
    img {
      width: 100%; }
    @media only screen and (max-width: $r-768) {
      display: block; } }
  .wrapper {
    padding: 40px 0;
    align-items: center;
    position: relative;
    &:nth-of-type(even) {
      div[class*="col-"] {
        &:first-of-type {
          order: 2; }
        &:nth-of-type(2) {
          order: 1; } } }
    div[class*="col-"] {
      @media only screen and (max-width: $r-768) {
        &:first-of-type {
          order: 2; }
        &:nth-of-type(2) {
          order: 1; } } }
    &:first-of-type {
      padding-bottom: 30px;
      @media only screen and (max-width: $r-768) {
        padding-bottom: 35px; } }
    &:nth-of-type(2) {
      padding: 30px 0 80px 0;
      @media only screen and (max-width: $r-768) {
        padding: 35px 0; }
      .shape {
        right: -80px;
        top: -130px; }
      img {
        max-height: 370px;
        @media only screen and (max-width: $r-768) {
          max-height: initial; } } }
    &:nth-of-type(3) {
      padding: 80px 0 35px 0;
      @media only screen and (max-width: $r-768) {
        padding: 35px 0; }
      .shape {
        left: -80px;
        top: -100px;
        @media only screen and (max-width: $r-992) {
          display: none; } } }
    &:nth-of-type(4) {
      padding: 35px 0 45px 0; }
    &:nth-of-type(5) {
      padding: 45px 0 40px 0;
      @media only screen and (max-width: $r-768) {
        padding: 35px 0; }
      .shape {
        left: -80px;
        @media only screen and (max-width: $r-992) {
          display: none; } } }
    .title-third {
      max-width: 400px;
      margin: 0 auto 25px auto;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 18px; } }
    p {
      max-width: 400px;
      margin: 0 auto;
      @media only screen and (max-width: $r-768) {
        font-size: 17px;
        line-height: 27.29px;
        margin-bottom: 30px; } }
    img {
      max-height: 320px;
      @media only screen and (max-width: $r-768) {
        max-height: initial; } }
    .shape {
      position: absolute;
      z-index: -1;
      img {
        max-height: 100%; } } } }

// Home feedback

.home-feedback {
  padding: 70px 0;
  overflow-x: hidden;
  @media only screen and (max-width: $r-768) {
    padding: 15px 0 70px 0; }
  .title-secondary {
    text-align: center;
    margin-bottom: 65px;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 30px; } }
  .wrapper {
    .flex {
      margin-bottom: 30px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 20px; }
      .swiper-logos {
        overflow: initial;
        .swiper-wrapper {
          -webkit-transition-timing-function: linear!important;
          -o-transition-timing-function: linear!important;
          transition-timing-function: linear!important; } }
      .item {
        max-width: 100%;
        width: 165px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #FAFAFA;
        box-shadow: 0 7px 55px rgba(89, 151, 223, 0.2);
        border-radius: 15px;
        position: relative;
        transition: .3s;
        top: 0;
        &:hover {
          transition: .3s;
          top: -10px; }
        img {
          max-width: 90%;
          max-height: 90%; }
        @media only screen and (max-width: $r-992) {
          width: 115px;
          height: 70px;
          img {
            width: 85%;
            max-height: 85%; } }
        @media only screen and (max-width: $r-768) {
          width: 100px;
          height: 52px;
          img {
            width: auto;
            max-width: 80%;
            max-height: 30px; } } } } }
  .slider {
    .swiper-feedback {
      max-width: 1260px;
      padding: 25px 0 40px 0;
      @media only screen and (max-width: $r-768) {
        padding-bottom: 0; }
      .swiper-button-next,
      .swiper-button-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: none;
        outline: none;
        width: 60px;
        height: 60px;
        top: 44%;
        margin: 0;
        background-color: white;
        box-shadow: 0 0 20px rgba(89, 151, 223, 0.22);
        border-radius: 10px;
        @media only screen and (max-width: $r-768) {
          width: 44px;
          height: 44px;
          svg {
            width: 30%; } } }
      .swiper-button-next {
        right: 20px;
        @media only screen and (max-width: $r-1200) {
          right: -5px; }
        @media only screen and (max-width: $r-768) {
          right: 5px; } }
      .swiper-button-prev {
        left: 20px;
        @media only screen and (max-width: $r-1200) {
          left: -5px; }
        @media only screen and (max-width: $r-768) {
          left: 5px; } }
      .swiper-button-disabled {
        opacity: 1;
        svg {
          path {
            fill: #ACBDD4; } } }
      .swiper-slide {
        .item {
          max-width: 1140px;
          margin: 0 auto;
          display: flex;
          border: 1px solid #FAFAFA;
          box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
          border-radius: 20px;
          @media only screen and (max-width: $r-768) {
            flex-direction: column;
            max-width: 290px;
            box-shadow: none;
            border: 0; }
          .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 60%;
            min-height: 385px;
            padding: 15px 55px 15px 95px;
            border-radius: 20px 20px 100px 20px;
            background: linear-gradient(108.57deg, #1F96FE 2.08%, #53A5F3 98.2%);
            @media only screen and (max-width: $r-768) {
              order: 2;
              width: 100%;
              padding: 30px 13px 30px 25px;
              border-radius: 20px 0 70px 20px; }
            h3 {
              max-width: 480px;
              font-style: normal;
              font-weight: bold;
              font-size: 23px;
              line-height: 154.2%;
              margin-bottom: 25px;
              color: white;
              @media only screen and (max-width: $r-768) {
                font-size: 22px;
                line-height: 33.92px; } }
            .text {
              position: relative;
              p {
                color: white;
                @media only screen and (max-width: $r-768) {
                  font-size: 17px;
                  line-height: 27.46px; } }
              svg {
                position: absolute;
                left: -35px;
                top: -15px;
                @media only screen and (max-width: $r-768) {
                  display: none; } } } }
          .logo {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            @media only screen and (max-width: $r-768) {
              order: 1;
              width: 100%;
              padding: 30px;
              box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
              border-radius: 20px 20px 0 0; }
            img {
              max-width: 300px;
              max-height: 80px; } } } } } } }

// Home integration

.home-integration {
  padding: 30px 0 70px 0;
  @media only screen and (max-width: $r-768) {
    padding: 30px 0 35px 0; }
  .wrapper {
    align-items: center;
    .title-secondary {
      margin-bottom: 38px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 17px; } }
    p {
      max-width: 520px;
      @media only screen and (max-width: $r-768) {
        font-size: 17px;
        line-height: 26.26px; } } } }

// Home get

.home-get {
  padding: 70px 0;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 35px 0 70px 0; }
  .bg {
    position: absolute;
    left: 0;
    bottom: -46%;
    width: 100%;
    object-fit: cover;
    object-position: top;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: none; }
    img {
      width: 100%; } }
  .bg-mobile {
    display: none;
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    object-fit: cover;
    object-position: top;
    z-index: -1;
    img {
      width: 100%; }
    @media only screen and (max-width: $r-768) {
      display: block; } }
  .wrapper {
    align-items: center;
    div[class*="col-"] {
      @media only screen and (max-width: $r-768) {
        &:first-of-type {
          order: 2; }
        &:nth-of-type(2) {
          order: 1; } } }
    .title-secondary {
      margin-bottom: 30px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 17px; } }
    p {
      max-width: 520px;
      &:last-of-type {
        margin-bottom: 42px; }
      @media only screen and (max-width: $r-768) {
        font-size: 17px;
        line-height: 26.26px;
        &:last-of-type {
          margin-bottom: 30px; } } }
    .btn-block {
      @media only screen and (max-width: $r-768) {
        margin-bottom: 30px;
        text-align: left;
        width: 100%; } } } }
