// About hero

.about-hero {
  padding-bottom: 0;
  margin-bottom: 85px;
  display: flex;
  align-items: flex-end;
  @media only screen and (max-width: $r-768) {
    align-items: flex-start;
    position: relative;
    background-image: url("../img/bg-about-hero-mobile.svg") !important;
    div[class*="col-"] {
      position: initial; } }
  img {
    max-width: 480px;
    margin-bottom: 15px;
    @media only screen and (max-width: $r-768) {
      max-width: 100%;
      margin-bottom: 0; }
    &:first-of-type {
      @media only screen and (max-width: $r-768) {
        display: none; } }
    &:nth-of-type(2) {
      display: none;
      @media only screen and (max-width: $r-768) {
        display: block; } } } }

// About text

.about-text {
  display: none;
  padding-bottom: 0;
  @media only screen and (max-width: $r-768) {
    margin: 0;
    z-index: 1; } }

// About things

.about-things {
  padding: 177px 0 125px 0;
  margin-top: -190px;
  z-index: 1;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 75px 0 60px 0;
    margin-top: 0; }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: none; }
    img {
      width: 100%; } }
  .bg-mobile {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: block; }
    img {
      width: 100%;
      height: 100%; } }
  .wrapper {
    align-items: center;
    div[class*="col-"] {
      @media only screen and (max-width: $r-768) {
        &:first-of-type {
          order: 2; }
        &:nth-of-type(2) {
          order: 1; } } }
    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 52px;
      line-height: 131.4%;
      margin: 100px 0 16px 0;
      color: white;
      @media only screen and (max-width: $r-768) {
        font-size: 42px;
        line-height: 131.4%;
        margin: 0 0 14px 0; } }
    p {
      font-size: 22px;
      color: white;
      @media only screen and (max-width: $r-768) {
        font-size: 18px;
        line-height: 166.4%; } }
    img {
      &:first-of-type {
        @media only screen and (max-width: $r-768) {
          display: none; } }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: $r-768) {
          display: block; } } } } }

// About story

.about-story {
  margin-top: -100px;
  padding-bottom: 95px;
  @media only screen and (max-width: $r-768) {
    margin-top: -40px;
    padding-bottom: 110px; }
  .item {
    max-width: 995px;
    margin: 0 auto;
    background: white;
    border: 1px solid #FAFAFA;
    box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
    border-radius: 20px;
    padding: 75px 95px 80px 95px;
    position: relative;
    z-index: 1;
    @media only screen and (max-width: $r-768) {
      padding: 32px 22px 71px 22px; }
    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("../img/shape-about-story-top.svg");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
      @media only screen and (max-width: $r-768) {
        background-image: url("../img/shape-about-story-top-mobile.svg"); } }
    &:after {
      position: absolute;
      content: '';
      right: 0;
      bottom: 0;
      width: 433px;
      height: 306px;
      background-image: url("../img/shape-about-story-bottom.svg");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
      @media only screen and (max-width: $r-768) {
        background-image: url("../img/shape-about-story-bottom-mobile.svg");
        width: 100%;
        height: 110px;
        background-size: cover; } }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 177.46%;
      margin-bottom: 40px;
      color: black;
      @media only screen and (max-width: $r-768) {
        font-size: 22px;
        margin-bottom: 30px; } }
    p {
      font-size: 22px;
      color: black;
      margin-bottom: 40px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 177.46%; } }
    .image {
      width: 625px;
      position: relative;
      @media only screen and (max-width: $r-768) {
        width: 100%; }
      .icon {
        position: absolute;
        right: -30px;
        bottom: -25px;
        z-index: 1;
        @media only screen and (max-width: $r-768) {
          right: initial;
          left: -10px;
          svg {
            width: 75px; } } } } } }

// About get

.about-get {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 145px 0 120px 0;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 78px 0 43px 0;
    margin-bottom: 60px; }
  .title-secondary {
    margin-bottom: 27px;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 25px; } }
  p {
    max-width: 535px;
    margin: 0 auto 16px auto;
    @media only screen and (max-width: $r-768) {
      max-width: 285px; }
    &:last-of-type {
      margin-bottom: 45px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 33px; } } }
  .shape {
    position: absolute;
    @media only screen and (max-width: $r-768) {
      display: none; } }
  .shape-mobile {
    display: none;
    position: absolute;
    @media only screen and (max-width: $r-768) {
      display: block; } } }
