@charset "UTF-8";
@media only screen and (max-width: 767px) {
  .container {
    padding: 0 15px; } }

body {
  font-family: "Montserrat", sans-serif !important;
  position: relative; }
  body:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 485px;
    max-width: 100%;
    height: 293px;
    background-image: url("../img/shape-body.svg");
    background-size: cover;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      body:before {
        background-image: none !important; } }
  body .container {
    max-width: 1170px; }
  body .modal-backdrop {
    background: rgba(31, 55, 69, 0.4); }
    body .modal-backdrop.show {
      opacity: 1; }

@media only screen and (max-width: 768px) {
  .show-desctop {
    display: none; } }

.show-mobile {
  display: none; }
  @media only screen and (max-width: 768px) {
    .show-mobile {
      display: block; } }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 156.4%;
  color: #444C6A; }
  @media only screen and (max-width: 768px) {
    p {
      font-size: 14px;
      line-height: 21.9px; } }
  p b,
  p strong {
    font-weight: 600; }

img {
  max-width: 100%;
  height: auto; }

.title-secondary {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  text-transform: capitalize;
  color: #0D142E; }
  @media only screen and (max-width: 768px) {
    .title-secondary {
      font-size: 29px;
      line-height: 35.35px; } }

.title-third {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  color: #0D142E; }
  @media only screen and (max-width: 768px) {
    .title-third {
      font-size: 29px;
      line-height: 35.35px; } }

.btn-green {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 48px;
  background-color: #00E08F;
  border-radius: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 156.4%;
  transition: .3s;
  color: white; }
  .btn-green:hover {
    transition: .3s;
    background-color: #01C880;
    text-decoration: none;
    color: white; }

.form-demo form {
  display: flex;
  max-width: 380px;
  height: 43px;
  border: 1px solid #ACBDD4;
  box-sizing: border-box;
  border-radius: 30px; }
  .form-demo form input[type="email"] {
    width: calc(100% - 155px);
    height: 41px;
    border-radius: 30px;
    border: 0;
    outline: 0;
    appearance: none;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 156.4%;
    padding-left: 24px;
    color: #3E2E64; }
    .form-demo form input[type="email"]::placeholder {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 156.4%;
      color: #3E2E64; }
  .form-demo form input[type="submit"] {
    width: 155px;
    height: 43px;
    border-radius: 0 30px 30px 0;
    border: 0;
    outline: 0;
    margin-top: -1px;
    position: relative;
    left: 1px;
    appearance: none;
    background-color: #00E08F;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 156.4%;
    transition: .3s;
    color: white; }
    .form-demo form input[type="submit"]:hover {
      transition: .3s;
      background-color: #01C880;
      color: white; }

.content {
  padding: 200px 0; }
  .content ul,
  .content ol {
    padding: 0;
    margin-left: 0; }
    .content ul li,
    .content ol li {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 156.4%;
      color: #444C6A; }
      @media only screen and (max-width: 768px) {
        .content ul li,
        .content ol li {
          font-size: 14px;
          line-height: 21.9px; } }
      .content ul li b,
      .content ul li strong,
      .content ol li b,
      .content ol li strong {
        font-weight: 600; }
  .content ul li {
    position: relative;
    padding-left: 20px; }
    .content ul li:before {
      position: absolute;
      left: 0;
      content: "•";
      font-weight: bold; }
  .content ol {
    counter-reset: item; }
    .content ol li:before {
      content: counter(item) ". ";
      counter-increment: item;
      font-weight: bold; }
  .content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
    color: #0d142e; }

::-webkit-scrollbar {
  width: 25px; }

::-webkit-scrollbar-track {
  background-color: white; }

::-webkit-scrollbar-thumb:vertical {
  -webkit-border-radius: 30px;
  border-radius: 30px;
  background: -webkit-linear-gradient(#5FAEF9, #2096FE); }

@font-face {
  font-family: 'Bebas';
  font-style: normal;
  font-weight: normal;
  src: local("Bebas"), url("../fonts/Bebas.woff") format("woff"); }

.header {
  width: 100%;
  padding: 25px 0;
  position: fixed;
  transition: .3s;
  background-color: transparent;
  z-index: 2; }
  @media only screen and (max-width: 992px) {
    .header {
      padding: 21px 0; } }
  .header.sticky {
    background-color: white;
    transition: .3s; }
  .header .flex {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .header nav ul {
    display: flex;
    margin-bottom: 0; }
    .header nav ul li {
      margin: 0 15px; }
      .header nav ul li a {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 156.4%;
        color: #0D142E; }
  .header .cta {
    margin-left: 40px; }
  .header .nav-mobile {
    width: 100%;
    display: none;
    background-color: white;
    box-shadow: 0 23px 40px rgba(89, 151, 223, 0.2);
    border-radius: 0 0 90px 20px;
    padding: 30px 0 50px 0;
    margin-top: 21px; }
    .header .nav-mobile.active {
      display: block; }
    .header .nav-mobile nav ul {
      flex-direction: column; }
      .header .nav-mobile nav ul li {
        padding: 10px 15px 10px 60px;
        margin: 0;
        position: relative; }
        .header .nav-mobile nav ul li.current-menu-item a {
          color: #1D99FF; }
        .header .nav-mobile nav ul li.current-menu-item:before {
          position: absolute;
          content: '';
          width: 5px;
          height: 100%;
          left: 0;
          top: 0;
          background: #1D99FF;
          border-radius: 5px; }
        .header .nav-mobile nav ul li a {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #0D142E; }
    .header .nav-mobile .cta {
      margin: 20px 0 0 60px; }

.footer {
  background-position: top;
  background-size: cover;
  padding: 140px 0 50px 0; }
  @media only screen and (max-width: 768px) {
    .footer {
      background-image: url("../img/bg-footer-mobile.svg") !important;
      padding-top: 130px; } }
  .footer .logo {
    text-align: right;
    margin-bottom: 90px; }
    @media only screen and (max-width: 768px) {
      .footer .logo {
        text-align: center;
        margin-bottom: 75px; }
        .footer .logo img {
          max-height: 45px; } }
  .footer .wrapper {
    align-items: flex-end; }
    @media only screen and (max-width: 768px) {
      .footer .wrapper {
        flex-direction: column; }
        .footer .wrapper div[class*="col-"]:first-of-type {
          order: 2; }
        .footer .wrapper div[class*="col-"]:nth-of-type(2) {
          order: 1; } }
    .footer .wrapper .avocadostories {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 135%;
      letter-spacing: 0.015em;
      margin-bottom: 25px;
      color: white; }
      @media only screen and (max-width: 768px) {
        .footer .wrapper .avocadostories {
          justify-content: center; } }
      .footer .wrapper .avocadostories svg {
        margin-left: 5px; }
    .footer .wrapper p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 0;
      color: white; }
      @media only screen and (max-width: 768px) {
        .footer .wrapper p {
          text-align: center; } }
    .footer .wrapper nav ul {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0; }
      @media only screen and (max-width: 768px) {
        .footer .wrapper nav ul {
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 24px; } }
      .footer .wrapper nav ul li {
        margin-left: 53px; }
        @media only screen and (max-width: 992px) {
          .footer .wrapper nav ul li {
            margin-left: 25px; } }
        @media only screen and (max-width: 768px) {
          .footer .wrapper nav ul li {
            margin: 0 15px 28px 15px !important; } }
        .footer .wrapper nav ul li:first-of-type {
          margin-left: 0; }
        .footer .wrapper nav ul li a {
          font-style: normal;
          font-weight: 500;
          font-size: 19px;
          line-height: 23px;
          color: white; }
          @media only screen and (max-width: 992px) {
            .footer .wrapper nav ul li a {
              font-size: 14px; } }
          @media only screen and (max-width: 768px) {
            .footer .wrapper nav ul li a {
              font-size: 21px;
              line-height: 25.6px; } }

.page-template-page-php:before {
  display: none; }

.home-hero {
  height: 100vh;
  max-height: 100vh;
  padding: 220px 0 50px 0;
  background-size: cover;
  background-position: top;
  position: relative; }
  @media only screen and (min-width: 769px) {
    .home-hero {
      background-image: none !important; } }
  @media only screen and (max-width: 768px) {
    .home-hero {
      padding: 95px 0;
      min-height: 600px;
      background-color: white;
      background-position: center; } }
  @media only screen and (max-height: 700px) {
    .home-hero {
      padding-top: 150px; } }
  @media only screen and (max-height: 600px) {
    .home-hero {
      padding-top: 115px; } }
  .home-hero .video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2; }
    @media only screen and (max-width: 768px) {
      .home-hero .video {
        display: none; } }
    .home-hero .video video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom; }
  @media only screen and (max-width: 768px) {
    .home-hero .item {
      text-align: center; } }
  .home-hero .item h1 {
    max-width: 550px;
    font-style: normal;
    font-weight: 800;
    font-size: 47px;
    line-height: 128.4%;
    text-transform: capitalize;
    margin-bottom: 21px;
    color: #0D142E; }
    @media only screen and (max-width: 768px) {
      .home-hero .item h1 {
        font-size: 28px;
        text-align: center;
        max-width: 320px;
        margin: 0 auto 21px auto; } }
    .home-hero .item h1 b,
    .home-hero .item h1 strong {
      font-weight: 800;
      color: #714AFF; }
  .home-hero .item p {
    max-width: 445px;
    color: #444C6A;
    margin-bottom: 37px; }
    @media only screen and (max-width: 768px) {
      .home-hero .item p {
        max-width: 320px;
        margin: 0 auto 25px; } }

.home-text {
  background: linear-gradient(89.56deg, #E4F3FF 0.11%, #FFFFFF 101.13%);
  filter: drop-shadow(0px 7px 70px rgba(89, 151, 223, 0.2));
  padding: 30px 0 20px 0; }
  @media only screen and (max-width: 768px) {
    .home-text {
      padding: 43px 0; } }
  .home-text h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #1D99FF; }
    @media only screen and (max-width: 768px) {
      .home-text h2 {
        margin-bottom: 10px; } }
  .home-text p {
    max-width: 780px;
    margin: 0 auto 16px auto;
    font-weight: 500;
    text-align: center;
    color: #3E2E64; }
    @media only screen and (max-width: 768px) {
      .home-text p {
        font-size: 17px;
        line-height: 26.59px; } }

.home-features {
  padding: 140px 0 70px 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .home-features {
      padding: 60px 0 30px 0; } }
  .home-features .shape-first {
    position: absolute;
    left: 0;
    top: -160px;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .home-features .shape-first {
        display: none; } }
  .home-features .shape-second {
    position: absolute;
    right: 0;
    top: 195px;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .home-features .shape-second {
        display: none; } }
  .home-features .shape-mobile {
    display: none;
    position: absolute;
    left: 0;
    top: -250px;
    width: 100%;
    z-index: -1; }
    .home-features .shape-mobile img {
      width: 100%; }
    @media only screen and (max-width: 768px) {
      .home-features .shape-mobile {
        display: block; } }
  .home-features .wrapper {
    padding: 40px 0;
    align-items: center;
    position: relative; }
    .home-features .wrapper:nth-of-type(even) div[class*="col-"]:first-of-type {
      order: 2; }
    .home-features .wrapper:nth-of-type(even) div[class*="col-"]:nth-of-type(2) {
      order: 1; }
    @media only screen and (max-width: 768px) {
      .home-features .wrapper div[class*="col-"]:first-of-type {
        order: 2; }
      .home-features .wrapper div[class*="col-"]:nth-of-type(2) {
        order: 1; } }
    .home-features .wrapper:first-of-type {
      padding-bottom: 30px; }
      @media only screen and (max-width: 768px) {
        .home-features .wrapper:first-of-type {
          padding-bottom: 35px; } }
    .home-features .wrapper:nth-of-type(2) {
      padding: 30px 0 80px 0; }
      @media only screen and (max-width: 768px) {
        .home-features .wrapper:nth-of-type(2) {
          padding: 35px 0; } }
      .home-features .wrapper:nth-of-type(2) .shape {
        right: -80px;
        top: -130px; }
      .home-features .wrapper:nth-of-type(2) img {
        max-height: 370px; }
        @media only screen and (max-width: 768px) {
          .home-features .wrapper:nth-of-type(2) img {
            max-height: initial; } }
    .home-features .wrapper:nth-of-type(3) {
      padding: 80px 0 35px 0; }
      @media only screen and (max-width: 768px) {
        .home-features .wrapper:nth-of-type(3) {
          padding: 35px 0; } }
      .home-features .wrapper:nth-of-type(3) .shape {
        left: -80px;
        top: -100px; }
        @media only screen and (max-width: 992px) {
          .home-features .wrapper:nth-of-type(3) .shape {
            display: none; } }
    .home-features .wrapper:nth-of-type(4) {
      padding: 35px 0 45px 0; }
    .home-features .wrapper:nth-of-type(5) {
      padding: 45px 0 40px 0; }
      @media only screen and (max-width: 768px) {
        .home-features .wrapper:nth-of-type(5) {
          padding: 35px 0; } }
      .home-features .wrapper:nth-of-type(5) .shape {
        left: -80px; }
        @media only screen and (max-width: 992px) {
          .home-features .wrapper:nth-of-type(5) .shape {
            display: none; } }
    .home-features .wrapper .title-third {
      max-width: 400px;
      margin: 0 auto 25px auto; }
      @media only screen and (max-width: 768px) {
        .home-features .wrapper .title-third {
          margin-bottom: 18px; } }
    .home-features .wrapper p {
      max-width: 400px;
      margin: 0 auto; }
      @media only screen and (max-width: 768px) {
        .home-features .wrapper p {
          font-size: 17px;
          line-height: 27.29px;
          margin-bottom: 30px; } }
    .home-features .wrapper img {
      max-height: 320px; }
      @media only screen and (max-width: 768px) {
        .home-features .wrapper img {
          max-height: initial; } }
    .home-features .wrapper .shape {
      position: absolute;
      z-index: -1; }
      .home-features .wrapper .shape img {
        max-height: 100%; }

.home-feedback {
  padding: 70px 0;
  overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    .home-feedback {
      padding: 15px 0 70px 0; } }
  .home-feedback .title-secondary {
    text-align: center;
    margin-bottom: 65px; }
    @media only screen and (max-width: 768px) {
      .home-feedback .title-secondary {
        margin-bottom: 30px; } }
  .home-feedback .wrapper .flex {
    margin-bottom: 30px; }
    @media only screen and (max-width: 768px) {
      .home-feedback .wrapper .flex {
        margin-bottom: 20px; } }
    .home-feedback .wrapper .flex .swiper-logos {
      overflow: initial; }
      .home-feedback .wrapper .flex .swiper-logos .swiper-wrapper {
        -webkit-transition-timing-function: linear !important;
        -o-transition-timing-function: linear !important;
        transition-timing-function: linear !important; }
    .home-feedback .wrapper .flex .item {
      max-width: 100%;
      width: 165px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #FAFAFA;
      box-shadow: 0 7px 55px rgba(89, 151, 223, 0.2);
      border-radius: 15px;
      position: relative;
      transition: .3s;
      top: 0; }
      .home-feedback .wrapper .flex .item:hover {
        transition: .3s;
        top: -10px; }
      .home-feedback .wrapper .flex .item img {
        max-width: 90%;
        max-height: 90%; }
      @media only screen and (max-width: 992px) {
        .home-feedback .wrapper .flex .item {
          width: 115px;
          height: 70px; }
          .home-feedback .wrapper .flex .item img {
            width: 85%;
            max-height: 85%; } }
      @media only screen and (max-width: 768px) {
        .home-feedback .wrapper .flex .item {
          width: 100px;
          height: 52px; }
          .home-feedback .wrapper .flex .item img {
            width: auto;
            max-width: 80%;
            max-height: 30px; } }
  .home-feedback .slider .swiper-feedback {
    max-width: 1260px;
    padding: 25px 0 40px 0; }
    @media only screen and (max-width: 768px) {
      .home-feedback .slider .swiper-feedback {
        padding-bottom: 0; } }
    .home-feedback .slider .swiper-feedback .swiper-button-next,
    .home-feedback .slider .swiper-feedback .swiper-button-prev {
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: none;
      outline: none;
      width: 60px;
      height: 60px;
      top: 44%;
      margin: 0;
      background-color: white;
      box-shadow: 0 0 20px rgba(89, 151, 223, 0.22);
      border-radius: 10px; }
      @media only screen and (max-width: 768px) {
        .home-feedback .slider .swiper-feedback .swiper-button-next,
        .home-feedback .slider .swiper-feedback .swiper-button-prev {
          width: 44px;
          height: 44px; }
          .home-feedback .slider .swiper-feedback .swiper-button-next svg,
          .home-feedback .slider .swiper-feedback .swiper-button-prev svg {
            width: 30%; } }
    .home-feedback .slider .swiper-feedback .swiper-button-next {
      right: 20px; }
      @media only screen and (max-width: 1200px) {
        .home-feedback .slider .swiper-feedback .swiper-button-next {
          right: -5px; } }
      @media only screen and (max-width: 768px) {
        .home-feedback .slider .swiper-feedback .swiper-button-next {
          right: 5px; } }
    .home-feedback .slider .swiper-feedback .swiper-button-prev {
      left: 20px; }
      @media only screen and (max-width: 1200px) {
        .home-feedback .slider .swiper-feedback .swiper-button-prev {
          left: -5px; } }
      @media only screen and (max-width: 768px) {
        .home-feedback .slider .swiper-feedback .swiper-button-prev {
          left: 5px; } }
    .home-feedback .slider .swiper-feedback .swiper-button-disabled {
      opacity: 1; }
      .home-feedback .slider .swiper-feedback .swiper-button-disabled svg path {
        fill: #ACBDD4; }
    .home-feedback .slider .swiper-feedback .swiper-slide .item {
      max-width: 1140px;
      margin: 0 auto;
      display: flex;
      border: 1px solid #FAFAFA;
      box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
      border-radius: 20px; }
      @media only screen and (max-width: 768px) {
        .home-feedback .slider .swiper-feedback .swiper-slide .item {
          flex-direction: column;
          max-width: 290px;
          box-shadow: none;
          border: 0; } }
      .home-feedback .slider .swiper-feedback .swiper-slide .item .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        min-height: 385px;
        padding: 15px 55px 15px 95px;
        border-radius: 20px 20px 100px 20px;
        background: linear-gradient(108.57deg, #1F96FE 2.08%, #53A5F3 98.2%); }
        @media only screen and (max-width: 768px) {
          .home-feedback .slider .swiper-feedback .swiper-slide .item .info {
            order: 2;
            width: 100%;
            padding: 30px 13px 30px 25px;
            border-radius: 20px 0 70px 20px; } }
        .home-feedback .slider .swiper-feedback .swiper-slide .item .info h3 {
          max-width: 480px;
          font-style: normal;
          font-weight: bold;
          font-size: 23px;
          line-height: 154.2%;
          margin-bottom: 25px;
          color: white; }
          @media only screen and (max-width: 768px) {
            .home-feedback .slider .swiper-feedback .swiper-slide .item .info h3 {
              font-size: 22px;
              line-height: 33.92px; } }
        .home-feedback .slider .swiper-feedback .swiper-slide .item .info .text {
          position: relative; }
          .home-feedback .slider .swiper-feedback .swiper-slide .item .info .text p {
            color: white; }
            @media only screen and (max-width: 768px) {
              .home-feedback .slider .swiper-feedback .swiper-slide .item .info .text p {
                font-size: 17px;
                line-height: 27.46px; } }
          .home-feedback .slider .swiper-feedback .swiper-slide .item .info .text svg {
            position: absolute;
            left: -35px;
            top: -15px; }
            @media only screen and (max-width: 768px) {
              .home-feedback .slider .swiper-feedback .swiper-slide .item .info .text svg {
                display: none; } }
      .home-feedback .slider .swiper-feedback .swiper-slide .item .logo {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media only screen and (max-width: 768px) {
          .home-feedback .slider .swiper-feedback .swiper-slide .item .logo {
            order: 1;
            width: 100%;
            padding: 30px;
            box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
            border-radius: 20px 20px 0 0; } }
        .home-feedback .slider .swiper-feedback .swiper-slide .item .logo img {
          max-width: 300px;
          max-height: 80px; }

.home-integration {
  padding: 30px 0 70px 0; }
  @media only screen and (max-width: 768px) {
    .home-integration {
      padding: 30px 0 35px 0; } }
  .home-integration .wrapper {
    align-items: center; }
    .home-integration .wrapper .title-secondary {
      margin-bottom: 38px; }
      @media only screen and (max-width: 768px) {
        .home-integration .wrapper .title-secondary {
          margin-bottom: 17px; } }
    .home-integration .wrapper p {
      max-width: 520px; }
      @media only screen and (max-width: 768px) {
        .home-integration .wrapper p {
          font-size: 17px;
          line-height: 26.26px; } }

.home-get {
  padding: 70px 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .home-get {
      padding: 35px 0 70px 0; } }
  .home-get .bg {
    position: absolute;
    left: 0;
    bottom: -46%;
    width: 100%;
    object-fit: cover;
    object-position: top;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .home-get .bg {
        display: none; } }
    .home-get .bg img {
      width: 100%; }
  .home-get .bg-mobile {
    display: none;
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    object-fit: cover;
    object-position: top;
    z-index: -1; }
    .home-get .bg-mobile img {
      width: 100%; }
    @media only screen and (max-width: 768px) {
      .home-get .bg-mobile {
        display: block; } }
  .home-get .wrapper {
    align-items: center; }
    @media only screen and (max-width: 768px) {
      .home-get .wrapper div[class*="col-"]:first-of-type {
        order: 2; }
      .home-get .wrapper div[class*="col-"]:nth-of-type(2) {
        order: 1; } }
    .home-get .wrapper .title-secondary {
      margin-bottom: 30px; }
      @media only screen and (max-width: 768px) {
        .home-get .wrapper .title-secondary {
          margin-bottom: 17px; } }
    .home-get .wrapper p {
      max-width: 520px; }
      .home-get .wrapper p:last-of-type {
        margin-bottom: 42px; }
      @media only screen and (max-width: 768px) {
        .home-get .wrapper p {
          font-size: 17px;
          line-height: 26.26px; }
          .home-get .wrapper p:last-of-type {
            margin-bottom: 30px; } }
    @media only screen and (max-width: 768px) {
      .home-get .wrapper .btn-block {
        margin-bottom: 30px;
        text-align: left;
        width: 100%; } }

.partners-hero {
  padding: 100px 0 15px 0;
  margin-bottom: 85px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right; }
  @media only screen and (max-width: 1200px) {
    .partners-hero {
      background-position: bottom; } }
  @media only screen and (max-width: 768px) {
    .partners-hero {
      background-image: url("../img/bg-partenrs-mobile.svg") !important;
      background-size: cover;
      padding-top: 88px; } }
  .partners-hero h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 58px;
    line-height: 141.4%;
    text-transform: capitalize;
    margin-bottom: 40px;
    color: #0D142E; }
    @media only screen and (max-width: 768px) {
      .partners-hero h1 {
        font-size: 36px;
        line-height: 50.9px; } }
  .partners-hero img {
    max-width: 480px; }
    @media only screen and (max-width: 768px) {
      .partners-hero img {
        max-width: 100%; } }

.partners-text {
  display: none;
  margin-top: -15px;
  padding-bottom: 115px; }
  @media only screen and (max-width: 768px) {
    .partners-text {
      background: linear-gradient(91.54deg, #2397FE 6.46%, #5BADF9 103.19%);
      padding: 25px 0;
      margin: -40px 0 85px 0; } }
  .partners-text .item {
    background: linear-gradient(91.54deg, #2397FE 6.46%, #5BADF9 103.19%);
    padding: 45px 0 30px 0; }
    @media only screen and (max-width: 768px) {
      .partners-text .item {
        background: initial;
        padding: 0; } }
    .partners-text .item p {
      max-width: 900px;
      margin: 0 auto 16px auto;
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 176.4%;
      text-align: center;
      color: white; }
      @media only screen and (max-width: 768px) {
        .partners-text .item p {
          font-size: 15px;
          line-height: 26.46px; } }

.partners-list {
  padding: 50px 0;
  position: relative; }
  .partners-list .shape {
    position: absolute;
    bottom: -14%;
    left: 0;
    width: 100%;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .partners-list .shape {
        display: none; } }
    .partners-list .shape img {
      width: 100%; }
  .partners-list .shape-mobile {
    display: none;
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 100%;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .partners-list .shape-mobile {
        display: block; } }
    .partners-list .shape-mobile img {
      width: 100%; }
  .partners-list .item {
    min-height: 345px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #FAFAFA;
    background-color: white;
    box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
    border-radius: 20px;
    padding: 74px 30px 45px 35px;
    margin-bottom: 100px;
    position: relative;
    cursor: pointer; }
    @media only screen and (max-width: 768px) {
      .partners-list .item {
        min-height: 250px;
        padding: 60px 30px 35px 30px; } }
    .partners-list .item:hover {
      transition: .3s; }
      .partners-list .item:hover .logo {
        top: -10px; }
    .partners-list .item .logo {
      position: relative;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -130px 0 36px 0;
      background-color: white;
      width: 193px;
      height: 95px;
      border: 1px solid #FAFAFA;
      box-shadow: 0 7px 45px rgba(89, 151, 223, 0.17);
      border-radius: 15px;
      transition: .3s; }
      @media only screen and (max-width: 768px) {
        .partners-list .item .logo {
          width: 170px;
          height: 83px;
          margin-top: -115px; } }
    .partners-list .item h3 {
      width: 100%;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 10px;
      text-align: left;
      color: #359CF9; }
      @media only screen and (max-width: 768px) {
        .partners-list .item h3 {
          font-size: 20px;
          line-height: 24.38px; } }
    .partners-list .item p {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 166.2%;
      color: rgba(0, 0, 0, 0.8); }
      @media only screen and (max-width: 768px) {
        .partners-list .item p {
          font-size: 13px;
          line-height: 21.61px; } }
    .partners-list .item .category {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 200px;
      height: 43px;
      background: #FF017B;
      border-radius: 0 20px 0 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 154.2%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: white; }
      @media only screen and (max-width: 768px) {
        .partners-list .item .category {
          width: 175px;
          height: 37px;
          font-size: 13px; } }

.partners-modal .modal-dialog {
  max-width: 740px; }

.partners-modal .modal-content {
  border: 1px solid #FAFAFA;
  box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
  border-radius: 20px;
  position: relative; }
  .partners-modal .modal-content:before {
    position: absolute;
    content: '';
    right: 0;
    background-image: url("../img/bg-modal.svg");
    background-size: cover;
    border-radius: 20px;
    width: 40%;
    height: 73%; }
  .partners-modal .modal-content .modal-body {
    padding: 85px 65px 35px 65px; }
    @media only screen and (max-width: 768px) {
      .partners-modal .modal-content .modal-body {
        padding: 45px 25px 20px 25px; } }
  .partners-modal .modal-content .close-modal {
    position: absolute;
    right: 23px;
    top: 12px;
    z-index: 1;
    cursor: pointer; }
    @media only screen and (max-width: 768px) {
      .partners-modal .modal-content .close-modal {
        right: 10px;
        top: 27px; } }
    .partners-modal .modal-content .close-modal:hover {
      transition: .3s; }
      .partners-modal .modal-content .close-modal:hover .arrows {
        fill: #714AFF; }
    .partners-modal .modal-content .close-modal svg path {
      transition: .3s; }
  .partners-modal .modal-content .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -130px 0 40px 0;
    width: 197px;
    height: 96px;
    background: white;
    border: 1px solid #FAFAFA;
    box-shadow: 0 7px 45px rgba(89, 151, 223, 0.17);
    border-radius: 15px; }
    @media only screen and (max-width: 768px) {
      .partners-modal .modal-content .logo {
        margin: -115px auto 25px auto; } }
  .partners-modal .modal-content .flex {
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 768px) {
      .partners-modal .modal-content .flex {
        flex-direction: column;
        align-items: flex-start; } }
    .partners-modal .modal-content .flex h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 23px;
      color: #359CF9; }
      @media only screen and (max-width: 768px) {
        .partners-modal .modal-content .flex h3 {
          margin-bottom: 11px;
          order: 2; } }
  .partners-modal .modal-content a {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 170.2%;
    text-decoration-line: underline;
    color: #714AFF; }
    @media only screen and (max-width: 768px) {
      .partners-modal .modal-content a {
        order: 1;
        margin-bottom: 20px;
        font-size: 14px; } }
    .partners-modal .modal-content a:hover {
      transition: .3s; }
      .partners-modal .modal-content a:hover svg {
        left: 5px; }
    .partners-modal .modal-content a svg {
      margin-left: 10px;
      position: relative;
      left: 0;
      transition: .3s; }
  .partners-modal .modal-content p {
    max-width: 600px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 170.2%;
    color: rgba(0, 0, 0, 0.8); }
    @media only screen and (max-width: 768px) {
      .partners-modal .modal-content p {
        font-size: 13px;
        line-height: 22.13px; } }

.page-template-page-demo .header .cta {
  display: none; }

.demo {
  padding: 150px 0 85px 0;
  position: relative; }
  .demo .shape {
    position: absolute;
    bottom: -290px;
    left: 0;
    width: 100%;
    z-index: -1; }
    @media only screen and (max-width: 992px) {
      .demo .shape {
        bottom: -200px; } }
    @media only screen and (max-width: 768px) {
      .demo .shape {
        bottom: -70px; } }
    .demo .shape img {
      width: 100%; }
  .demo p {
    max-width: 730px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 141.4%;
    text-align: center;
    color: #1D99FF; }

.stories-hero {
  padding-bottom: 0;
  margin-bottom: 85px;
  display: flex;
  align-items: flex-end; }
  @media only screen and (max-width: 768px) {
    .stories-hero {
      align-items: flex-start;
      position: relative;
      background-image: url("../img/bg-stories-hero-mob.svg") !important; }
      .stories-hero div[class*="col-"] {
        position: initial; } }
  @media only screen and (max-width: 768px) {
    .stories-hero h1 {
      max-width: 285px;
      margin: 0 auto 40px auto; } }
  .stories-hero img {
    max-width: 480px;
    margin-bottom: -22px; }
    @media only screen and (max-width: 768px) {
      .stories-hero img {
        max-width: 100%;
        margin-bottom: 0; } }

.stories-text {
  display: none;
  margin-top: 0; }
  @media only screen and (max-width: 768px) {
    .stories-text {
      position: relative;
      z-index: 1; } }

.stories-list {
  position: relative; }
  .stories-list .bg {
    position: absolute;
    bottom: 26%;
    left: 0;
    width: 100%;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .stories-list .bg {
        display: none; } }
    .stories-list .bg img {
      width: 100%; }
  .stories-list .bg-mobile {
    display: none;
    position: absolute;
    bottom: 30%;
    left: 0;
    width: 100%;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .stories-list .bg-mobile {
        display: block; } }
    .stories-list .bg-mobile img {
      width: 100%; }
  .stories-list .wrapper {
    display: flex;
    justify-content: space-between;
    background: white;
    box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
    border-radius: 20px;
    padding: 65px 30px 60px 30px;
    margin-bottom: 140px;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .stories-list .wrapper {
        flex-direction: column;
        padding: 35px 15px 60px 15px;
        margin-bottom: 25px; } }
    .stories-list .wrapper:hover {
      transition: .3s; }
      .stories-list .wrapper:hover .info .logo {
        top: -10px; }
    .stories-list .wrapper:nth-of-type(odd) {
      background-image: url("../img/vector-stories-list-1.svg");
      background-position: left top;
      background-repeat: no-repeat; }
      @media only screen and (max-width: 768px) {
        .stories-list .wrapper:nth-of-type(odd) {
          background-image: url("../img/vector-stories-list-3.svg");
          background-size: contain; } }
    .stories-list .wrapper:nth-of-type(even) {
      background-image: url("../img/vector-stories-list-2.svg");
      background-position: left bottom;
      background-repeat: no-repeat;
      padding: 65px 25px 60px 60px; }
      @media only screen and (max-width: 768px) {
        .stories-list .wrapper:nth-of-type(even) {
          padding: 35px 15px 60px 15px;
          background-image: url("../img/vector-stories-list-3.svg");
          background-position: left top;
          background-repeat: no-repeat;
          background-size: contain; } }
      .stories-list .wrapper:nth-of-type(even) .download {
        order: 1;
        right: initial;
        left: 0;
        border-radius: 0 20px 0 20px; }
        @media only screen and (max-width: 768px) {
          .stories-list .wrapper:nth-of-type(even) .download {
            order: 3; } }
      .stories-list .wrapper:nth-of-type(even) .info {
        order: 3;
        padding-left: 35px;
        padding-right: 0;
        border-right: 0;
        border-left: 1px solid rgba(31, 59, 85, 0.3); }
        @media only screen and (max-width: 768px) {
          .stories-list .wrapper:nth-of-type(even) .info {
            order: 1;
            padding-left: 0;
            border-left: 0; } }
      .stories-list .wrapper:nth-of-type(even) .content {
        order: 2;
        padding: 0 60px 0 0; }
        @media only screen and (max-width: 768px) {
          .stories-list .wrapper:nth-of-type(even) .content {
            order: 2;
            padding-right: 0; } }
    .stories-list .wrapper .download {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 54px;
      min-width: 330px;
      background: #00E08F;
      border-radius: 20px 0 20px 0; }
      @media only screen and (max-width: 768px) {
        .stories-list .wrapper .download {
          height: 46px;
          max-width: 275px;
          min-width: auto;
          width: 100%; } }
      .stories-list .wrapper .download a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 154.2%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFF; }
        @media only screen and (max-width: 768px) {
          .stories-list .wrapper .download a {
            font-size: 13px; } }
        .stories-list .wrapper .download a svg {
          margin-left: 9px; }
    .stories-list .wrapper .info {
      width: 380px;
      border-right: 1px solid rgba(31, 59, 85, 0.3);
      padding-right: 35px; }
      @media only screen and (max-width: 768px) {
        .stories-list .wrapper .info {
          width: 100%;
          padding-right: 0;
          border-right: 0;
          border-bottom: 1px solid rgba(31, 59, 85, 0.3); } }
      .stories-list .wrapper .info .text {
        position: relative; }
        .stories-list .wrapper .info .text p {
          max-width: 280px;
          margin: 0 auto 10px auto;
          font-style: italic;
          font-weight: bold;
          font-size: 21px;
          line-height: 166.2%;
          text-align: center;
          color: #0D142E; }
          @media only screen and (max-width: 768px) {
            .stories-list .wrapper .info .text p {
              max-width: 260px;
              font-size: 19px; } }
        .stories-list .wrapper .info .text svg {
          position: absolute;
          left: -20px;
          top: -15px; }
          @media only screen and (max-width: 768px) {
            .stories-list .wrapper .info .text svg {
              left: -7px;
              width: 40px; } }
      .stories-list .wrapper .info .avatar {
        margin-bottom: 15px;
        text-align: center; }
        .stories-list .wrapper .info .avatar img {
          height: 130px; }
          @media only screen and (max-width: 768px) {
            .stories-list .wrapper .info .avatar img {
              height: 125px; } }
      .stories-list .wrapper .info .about {
        max-width: 302px;
        text-align: center; }
        .stories-list .wrapper .info .about p {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 166.2%;
          text-align: center;
          margin-bottom: 30px;
          color: #000; }
        .stories-list .wrapper .info .about b {
          display: block;
          font-weight: 700; }
      .stories-list .wrapper .info .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 250px;
        height: 110px;
        margin: 0 auto -95px auto;
        background: #FFF;
        border: 1px solid #FAFAFA;
        box-shadow: 0 7px 45px rgba(89, 151, 223, 0.17);
        border-radius: 15px;
        position: relative;
        top: 0;
        transition: .3s; }
        @media only screen and (max-width: 768px) {
          .stories-list .wrapper .info .logo {
            margin-bottom: 32px; } }
        .stories-list .wrapper .info .logo img {
          max-width: 80%;
          max-height: 90px; }
    .stories-list .wrapper .content {
      width: calc(100% - 380px);
      padding: 0 0 0 60px; }
      @media only screen and (max-width: 768px) {
        .stories-list .wrapper .content {
          padding: 22px 0 0 0;
          width: 100%; } }
      .stories-list .wrapper .content h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #359CF9; }
        @media only screen and (max-width: 768px) {
          .stories-list .wrapper .content h3 {
            font-size: 21px; } }
      .stories-list .wrapper .content p {
        max-width: 675px; }
        .stories-list .wrapper .content p b,
        .stories-list .wrapper .content p strong {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          margin-right: 10px;
          color: #714AFF; }
          @media only screen and (max-width: 768px) {
            .stories-list .wrapper .content p b,
            .stories-list .wrapper .content p strong {
              display: block;
              font-size: 21px;
              margin-bottom: 5px; } }

@media only screen and (max-width: 768px) {
  .stories-get {
    padding: 65px 0 100px 0; } }

.stories-get .bg {
  bottom: -45%; }

.stories-get .bg-mobile {
  top: 375px; }

.stories-get .wrapper img {
  max-width: 500px; }
  @media only screen and (max-width: 768px) {
    .stories-get .wrapper img {
      max-width: 100%; } }

.about-hero {
  padding-bottom: 0;
  margin-bottom: 85px;
  display: flex;
  align-items: flex-end; }
  @media only screen and (max-width: 768px) {
    .about-hero {
      align-items: flex-start;
      position: relative;
      background-image: url("../img/bg-about-hero-mobile.svg") !important; }
      .about-hero div[class*="col-"] {
        position: initial; } }
  .about-hero img {
    max-width: 480px;
    margin-bottom: 15px; }
    @media only screen and (max-width: 768px) {
      .about-hero img {
        max-width: 100%;
        margin-bottom: 0; } }
    @media only screen and (max-width: 768px) {
      .about-hero img:first-of-type {
        display: none; } }
    .about-hero img:nth-of-type(2) {
      display: none; }
      @media only screen and (max-width: 768px) {
        .about-hero img:nth-of-type(2) {
          display: block; } }

.about-text {
  display: none;
  padding-bottom: 0; }
  @media only screen and (max-width: 768px) {
    .about-text {
      margin: 0;
      z-index: 1; } }

.about-things {
  padding: 177px 0 125px 0;
  margin-top: -190px;
  z-index: 1;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .about-things {
      padding: 75px 0 60px 0;
      margin-top: 0; } }
  .about-things .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .about-things .bg {
        display: none; } }
    .about-things .bg img {
      width: 100%; }
  .about-things .bg-mobile {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .about-things .bg-mobile {
        display: block; } }
    .about-things .bg-mobile img {
      width: 100%;
      height: 100%; }
  .about-things .wrapper {
    align-items: center; }
    @media only screen and (max-width: 768px) {
      .about-things .wrapper div[class*="col-"]:first-of-type {
        order: 2; }
      .about-things .wrapper div[class*="col-"]:nth-of-type(2) {
        order: 1; } }
    .about-things .wrapper h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 52px;
      line-height: 131.4%;
      margin: 100px 0 16px 0;
      color: white; }
      @media only screen and (max-width: 768px) {
        .about-things .wrapper h2 {
          font-size: 42px;
          line-height: 131.4%;
          margin: 0 0 14px 0; } }
    .about-things .wrapper p {
      font-size: 22px;
      color: white; }
      @media only screen and (max-width: 768px) {
        .about-things .wrapper p {
          font-size: 18px;
          line-height: 166.4%; } }
    @media only screen and (max-width: 768px) {
      .about-things .wrapper img:first-of-type {
        display: none; } }
    .about-things .wrapper img:nth-of-type(2) {
      display: none; }
      @media only screen and (max-width: 768px) {
        .about-things .wrapper img:nth-of-type(2) {
          display: block; } }

.about-story {
  margin-top: -100px;
  padding-bottom: 95px; }
  @media only screen and (max-width: 768px) {
    .about-story {
      margin-top: -40px;
      padding-bottom: 110px; } }
  .about-story .item {
    max-width: 995px;
    margin: 0 auto;
    background: white;
    border: 1px solid #FAFAFA;
    box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
    border-radius: 20px;
    padding: 75px 95px 80px 95px;
    position: relative;
    z-index: 1; }
    @media only screen and (max-width: 768px) {
      .about-story .item {
        padding: 32px 22px 71px 22px; } }
    .about-story .item:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("../img/shape-about-story-top.svg");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1; }
      @media only screen and (max-width: 768px) {
        .about-story .item:before {
          background-image: url("../img/shape-about-story-top-mobile.svg"); } }
    .about-story .item:after {
      position: absolute;
      content: '';
      right: 0;
      bottom: 0;
      width: 433px;
      height: 306px;
      background-image: url("../img/shape-about-story-bottom.svg");
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1; }
      @media only screen and (max-width: 768px) {
        .about-story .item:after {
          background-image: url("../img/shape-about-story-bottom-mobile.svg");
          width: 100%;
          height: 110px;
          background-size: cover; } }
    .about-story .item h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 177.46%;
      margin-bottom: 40px;
      color: black; }
      @media only screen and (max-width: 768px) {
        .about-story .item h3 {
          font-size: 22px;
          margin-bottom: 30px; } }
    .about-story .item p {
      font-size: 22px;
      color: black;
      margin-bottom: 40px; }
      @media only screen and (max-width: 768px) {
        .about-story .item p {
          margin-bottom: 30px;
          font-size: 18px;
          line-height: 177.46%; } }
    .about-story .item .image {
      width: 625px;
      position: relative; }
      @media only screen and (max-width: 768px) {
        .about-story .item .image {
          width: 100%; } }
      .about-story .item .image .icon {
        position: absolute;
        right: -30px;
        bottom: -25px;
        z-index: 1; }
        @media only screen and (max-width: 768px) {
          .about-story .item .image .icon {
            right: initial;
            left: -10px; }
            .about-story .item .image .icon svg {
              width: 75px; } }

.about-get {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 145px 0 120px 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .about-get {
      padding: 78px 0 43px 0;
      margin-bottom: 60px; } }
  .about-get .title-secondary {
    margin-bottom: 27px; }
    @media only screen and (max-width: 768px) {
      .about-get .title-secondary {
        margin-bottom: 25px; } }
  .about-get p {
    max-width: 535px;
    margin: 0 auto 16px auto; }
    @media only screen and (max-width: 768px) {
      .about-get p {
        max-width: 285px; } }
    .about-get p:last-of-type {
      margin-bottom: 45px; }
      @media only screen and (max-width: 768px) {
        .about-get p:last-of-type {
          margin-bottom: 33px; } }
  .about-get .shape {
    position: absolute; }
    @media only screen and (max-width: 768px) {
      .about-get .shape {
        display: none; } }
  .about-get .shape-mobile {
    display: none;
    position: absolute; }
    @media only screen and (max-width: 768px) {
      .about-get .shape-mobile {
        display: block; } }

.blog-hero {
  padding: 100px 0 15px 0;
  margin-bottom: 85px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right; }
  @media only screen and (max-width: 1200px) {
    .blog-hero {
      background-position: bottom; } }
  @media only screen and (max-width: 768px) {
    .blog-hero {
      background-image: url("../img/bg-blog-mobile.svg") !important;
      background-size: cover;
      padding: 88px 0 55px 0;
      margin-bottom: 78px; } }
  .blog-hero h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 58px;
    line-height: 141.4%;
    text-transform: capitalize;
    margin-bottom: 40px;
    color: #0D142E; }
    @media only screen and (max-width: 768px) {
      .blog-hero h1 {
        font-size: 36px;
        line-height: 50.9px;
        margin-bottom: 140px; } }
  .blog-hero img {
    max-width: 480px; }
    @media only screen and (max-width: 768px) {
      .blog-hero img {
        max-width: 100%; } }

.blog-list {
  padding: 10px 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .blog-list {
      padding: 0; } }
  .blog-list .shape {
    position: absolute;
    left: 0;
    top: 90px;
    width: 100%;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .blog-list .shape {
        top: 120px; } }
    @media only screen and (max-width: 768px) {
      .blog-list .shape img:first-of-type {
        display: none; } }
    .blog-list .shape img:nth-of-type(2) {
      width: 100%;
      display: none; }
      @media only screen and (max-width: 768px) {
        .blog-list .shape img:nth-of-type(2) {
          display: block; } }
  .blog-list .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px; }
    @media only screen and (max-width: 768px) {
      .blog-list .item {
        margin-bottom: 36px; } }
    .blog-list .item:hover {
      text-decoration: none; }
    .blog-list .item .image {
      margin-bottom: 22px; }
      .blog-list .item .image img {
        height: 240px;
        object-fit: cover;
        border-radius: 14px; }
        @media only screen and (max-width: 768px) {
          .blog-list .item .image img {
            height: 214px; } }
    .blog-list .item .info .flex {
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
      .blog-list .item .info .flex .category {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-right: 14px;
        color: #4F6AFF; }
      .blog-list .item .info .flex .read {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 12px;
        letter-spacing: 0.5px;
        color: rgba(18, 18, 18, 0.5); }
    .blog-list .item .info h3 {
      min-height: 60px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #0D142E; }
      @media only screen and (max-width: 768px) {
        .blog-list .item .info h3 {
          font-size: 18px;
          line-height: 26px; } }
    .blog-list .item.large-item {
      margin-bottom: 120px;
      flex-direction: row;
      flex-wrap: wrap; }
      @media only screen and (max-width: 768px) {
        .blog-list .item.large-item {
          margin-bottom: 84px; } }
      .blog-list .item.large-item .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        background: #EFF9FF;
        border-radius: 24px 0 0 24px;
        padding: 15px 74px 15px 74px; }
        @media only screen and (max-width: 768px) {
          .blog-list .item.large-item .info {
            order: 2;
            width: 100%;
            padding: 35px 25px 45px 25px;
            border-radius: 0 0 24px 24px; } }
        .blog-list .item.large-item .info h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 49px;
          margin-bottom: 28px;
          color: #000; }
          @media only screen and (max-width: 768px) {
            .blog-list .item.large-item .info h3 {
              font-size: 34px;
              line-height: 41px;
              margin-bottom: 25px; } }
        .blog-list .item.large-item .info .text {
          margin-bottom: 75px; }
          @media only screen and (max-width: 768px) {
            .blog-list .item.large-item .info .text {
              margin-bottom: 24px; } }
          .blog-list .item.large-item .info .text p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: rgba(13, 20, 46, 0.8); }
            @media only screen and (max-width: 768px) {
              .blog-list .item.large-item .info .text p {
                font-size: 14px; } }
        .blog-list .item.large-item .info .flex .read {
          font-size: 15px; }
      .blog-list .item.large-item .image {
        width: 50%;
        margin-bottom: 0; }
        @media only screen and (max-width: 768px) {
          .blog-list .item.large-item .image {
            order: 1;
            width: 100%; } }
        .blog-list .item.large-item .image img {
          min-height: 593px;
          border-radius: 0 24px 24px 0; }
          @media only screen and (max-width: 768px) {
            .blog-list .item.large-item .image img {
              min-height: 289px;
              border-radius: 24px 24px 0 0; } }
      .blog-list .item.large-item .more {
        text-align: right;
        width: 100%;
        margin-top: 28px; }
        @media only screen and (max-width: 768px) {
          .blog-list .item.large-item .more {
            order: 3;
            text-align: center; } }
        .blog-list .item.large-item .more .btn-green {
          display: inline-flex;
          height: 46px;
          min-width: 165px; }
          .blog-list .item.large-item .more .btn-green svg {
            margin-left: 7px; }
  .blog-list .wp-pagenavi {
    display: flex;
    justify-content: flex-end;
    margin-top: -40px; }
    .blog-list .wp-pagenavi a,
    .blog-list .wp-pagenavi span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      border: 0;
      margin: 0 5px;
      padding: 0;
      width: 27px;
      height: 27px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #0D142E; }
      .blog-list .wp-pagenavi a.pages,
      .blog-list .wp-pagenavi span.pages {
        display: none; }
      .blog-list .wp-pagenavi a:hover,
      .blog-list .wp-pagenavi span:hover {
        border: 0; }
      .blog-list .wp-pagenavi a.current,
      .blog-list .wp-pagenavi span.current {
        font-weight: bold;
        color: #3EA2FB; }
      .blog-list .wp-pagenavi a.nextpostslink, .blog-list .wp-pagenavi a.previouspostslink,
      .blog-list .wp-pagenavi span.nextpostslink,
      .blog-list .wp-pagenavi span.previouspostslink {
        position: relative; }
        .blog-list .wp-pagenavi a.nextpostslink:before, .blog-list .wp-pagenavi a.previouspostslink:before,
        .blog-list .wp-pagenavi span.nextpostslink:before,
        .blog-list .wp-pagenavi span.previouspostslink:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background-image: url("../img/icon-pagination.svg");
          background-size: 6px;
          background-repeat: no-repeat;
          background-position: center; }
      .blog-list .wp-pagenavi a.previouspostslink:before,
      .blog-list .wp-pagenavi span.previouspostslink:before {
        transform: rotate(180deg); }

.paged .blog-list .hide-paged {
  display: none; }

.blog-get {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 145px 0 120px 0;
  margin-bottom: -100px;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .blog-get {
      padding: 78px 0 43px 0;
      margin-bottom: 60px; } }
  .blog-get .title-secondary {
    margin-bottom: 22px; }
    @media only screen and (max-width: 768px) {
      .blog-get .title-secondary {
        max-width: 180px;
        margin: 0 auto 25px auto; } }
  .blog-get p {
    max-width: 535px;
    margin: 0 auto 16px auto; }
    @media only screen and (max-width: 768px) {
      .blog-get p {
        max-width: 285px; } }
    .blog-get p:last-of-type {
      margin-bottom: 45px; }
      @media only screen and (max-width: 768px) {
        .blog-get p:last-of-type {
          margin-bottom: 33px; } }
  .blog-get .shape {
    position: absolute; }
    @media only screen and (max-width: 768px) {
      .blog-get .shape {
        display: none; } }
  .blog-get .shape-mobile {
    display: none;
    position: absolute;
    width: 100%; }
    .blog-get .shape-mobile img {
      width: 100%; }
    @media only screen and (max-width: 768px) {
      .blog-get .shape-mobile {
        display: block; } }

.blog-single {
  padding: 130px 0;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .blog-single {
      padding-bottom: 80px; } }
  .blog-single .shape {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1; }
    @media only screen and (max-width: 768px) {
      .blog-single .shape img:first-of-type {
        display: none; } }
    .blog-single .shape img:nth-of-type(2) {
      display: none; }
      @media only screen and (max-width: 768px) {
        .blog-single .shape img:nth-of-type(2) {
          display: block; } }
  .blog-single .content {
    max-width: 755px;
    padding: 0;
    margin: 0 auto; }
    .blog-single .content .image {
      margin-bottom: 22px; }
      .blog-single .content .image img {
        border-radius: 14px;
        margin-bottom: 0; }
        @media only screen and (max-width: 768px) {
          .blog-single .content .image img {
            height: 230px;
            object-fit: cover; } }
    .blog-single .content .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 35px; }
      @media only screen and (max-width: 768px) {
        .blog-single .content .flex {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 44px; } }
      .blog-single .content .flex .author {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 12px;
        letter-spacing: 0.5px;
        color: rgba(18, 18, 18, 0.7); }
        @media only screen and (max-width: 768px) {
          .blog-single .content .flex .author {
            font-size: 13px;
            margin-bottom: 27px; } }
      .blog-single .content .flex .socials {
        width: 50%; }
        @media only screen and (max-width: 768px) {
          .blog-single .content .flex .socials {
            width: 100%; } }
        .blog-single .content .flex .socials .a2a_kit {
          display: flex;
          justify-content: flex-end;
          line-height: initial; }
          @media only screen and (max-width: 768px) {
            .blog-single .content .flex .socials .a2a_kit {
              width: 100%;
              justify-content: center; } }
          .blog-single .content .flex .socials .a2a_kit a {
            display: flex;
            height: 50px;
            padding: 0; }
            .blog-single .content .flex .socials .a2a_kit a img {
              margin: 0;
              height: 100%;
              opacity: 1; }
              @media only screen and (max-width: 768px) {
                .blog-single .content .flex .socials .a2a_kit a img {
                  height: 60px; } }
    .blog-single .content h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 50px;
      text-transform: capitalize;
      margin-bottom: 36px;
      color: #0D142E; }
      @media only screen and (max-width: 768px) {
        .blog-single .content h1 {
          font-size: 30px;
          line-height: 40px;
          margin-bottom: 18px; } }
    .blog-single .content h2 {
      font-size: 26px;
      line-height: 28px;
      margin: 55px 0 27px 0; }
      @media only screen and (max-width: 768px) {
        .blog-single .content h2 {
          margin: 42px 0 28px 0; } }
    .blog-single .content h3 {
      font-size: 18px;
      line-height: 28px;
      margin: 42px 0 17px 0; }
    .blog-single .content h2,
    .blog-single .content h3,
    .blog-single .content h4,
    .blog-single .content h5,
    .blog-single .content h6 {
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;
      color: #0D142E; }
    .blog-single .content p,
    .blog-single .content ul li,
    .blog-single .content ol li {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: #0D142E; }
      @media only screen and (max-width: 768px) {
        .blog-single .content p,
        .blog-single .content ul li,
        .blog-single .content ol li {
          font-size: 14px;
          line-height: 24px; } }
    .blog-single .content p {
      margin-bottom: 24px; }
    .blog-single .content ol {
      padding-left: 20px;
      margin-bottom: 30px; }
      .blog-single .content ol li {
        margin-bottom: 15px;
        padding-right: 32px; }
    .blog-single .content ul {
      padding-left: 20px;
      margin-bottom: 30px; }
      .blog-single .content ul li {
        margin-bottom: 15px;
        padding-right: 32px;
        position: relative; }
        .blog-single .content ul li:before {
          position: absolute;
          content: '';
          left: 0;
          top: 8px;
          width: 9px;
          height: 9px;
          background: #8854DD;
          border-radius: 50px; }
    .blog-single .content img {
      margin: 25px 0; }
    .blog-single .content blockquote {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 580px;
      max-width: 100%;
      background-color: white;
      box-shadow: 0 18px 42.85px rgba(215, 228, 249, 0.45);
      border-radius: 20px;
      margin: 0 auto 30px auto;
      padding: 63px 70px;
      position: relative;
      z-index: 1; }
      @media only screen and (max-width: 768px) {
        .blog-single .content blockquote {
          padding: 50px 40px; } }
      .blog-single .content blockquote:before {
        position: absolute;
        content: '';
        background-image: url("../img/icon-quotes.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 186px;
        height: 133px;
        z-index: -1; }
        @media only screen and (max-width: 768px) {
          .blog-single .content blockquote:before {
            width: 99px;
            height: 80px;
            top: 25px; } }
      .blog-single .content blockquote p {
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 58px;
        letter-spacing: 0.01em;
        margin: 0;
        text-align: center;
        color: #0D142E; }
        @media only screen and (max-width: 768px) {
          .blog-single .content blockquote p {
            font-size: 34px;
            line-height: 44px; } }

.blog-related {
  padding-bottom: 50px;
  position: relative;
  overflow: hidden; }
  .blog-related .shape {
    position: absolute;
    top: 140px; }
  .blog-related .title-secondary {
    text-align: center;
    margin-bottom: 57px; }
    @media only screen and (max-width: 768px) {
      .blog-related .title-secondary {
        margin-bottom: 33px; } }
  .blog-related .wrapper .item {
    margin-bottom: 0; }
  .blog-related .swiper-related {
    padding-bottom: 75px; }
    .blog-related .swiper-related .arrows {
      display: flex;
      justify-content: center;
      margin-top: -30px; }
      .blog-related .swiper-related .arrows .swiper-button-next,
      .blog-related .swiper-related .arrows .swiper-button-prev {
        background-image: none;
        margin: 0;
        position: initial;
        width: 75px;
        height: 75px;
        outline: none; }
