// Demo

.page-template-page-demo {
  .header {
    .cta {
      display: none; } } }

.demo {
  padding: 150px 0 85px 0;
  position: relative;
  .shape {
    position: absolute;
    bottom: -290px;
    left: 0;
    width: 100%;
    z-index: -1;
    @media only screen and (max-width: $r-992) {
      bottom: -200px; }
    @media only screen and (max-width: $r-768) {
      bottom: -70px; }
    img {
      width: 100%; } }
  p {
    max-width: 730px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 141.4%;
    text-align: center;
    color: #1D99FF; } }
