// Stories hero

.stories-hero {
  padding-bottom: 0;
  margin-bottom: 85px;
  display: flex;
  align-items: flex-end;
  @media only screen and (max-width: $r-768) {
    align-items: flex-start;
    position: relative;
    background-image: url("../img/bg-stories-hero-mob.svg") !important;
    div[class*="col-"] {
      position: initial; } }
  h1 {
    @media only screen and (max-width: $r-768) {
      max-width: 285px;
      margin: 0 auto 40px auto; } }
  img {
    max-width: 480px;
    margin-bottom: -22px;
    @media only screen and (max-width: $r-768) {
      max-width: 100%;
      margin-bottom: 0; } } }


// Stories text

.stories-text {
  display: none;
  margin-top: 0;
  @media only screen and (max-width: $r-768) {
    position: relative;
    z-index: 1; } }

// Stories list

.stories-list {
  position: relative;
  .bg {
    position: absolute;
    bottom: 26%;
    left: 0;
    width: 100%;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: none; }
    img {
      width: 100%; } }
  .bg-mobile {
    display: none;
    position: absolute;
    bottom: 30%;
    left: 0;
    width: 100%;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: block; }
    img {
      width: 100%; } }
  .wrapper {
    display: flex;
    justify-content: space-between;
    background: white;
    //border: 1px solid #FAFAFA
    box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
    border-radius: 20px;
    padding: 65px 30px 60px 30px;
    margin-bottom: 140px;
    position: relative;
    @media only screen and (max-width: $r-768) {
      flex-direction: column;
      padding: 35px 15px 60px 15px;
      margin-bottom: 25px; }
    &:hover {
      transition: .3s;
      .info {
        .logo {
          top: -10px; } } }
    &:nth-of-type(odd) {
      background-image: url("../img/vector-stories-list-1.svg");
      background-position: left top;
      background-repeat: no-repeat;
      @media only screen and (max-width: $r-768) {
        background-image: url("../img/vector-stories-list-3.svg");
        background-size: contain; } }
    &:nth-of-type(even) {
      background-image: url("../img/vector-stories-list-2.svg");
      background-position: left bottom;
      background-repeat: no-repeat;
      padding: 65px 25px 60px 60px;
      @media only screen and (max-width: $r-768) {
        padding: 35px 15px 60px 15px;
        background-image: url("../img/vector-stories-list-3.svg");
        background-position: left top;
        background-repeat: no-repeat;
        background-size: contain; }
      .download {
        order: 1;
        right: initial;
        left: 0;
        border-radius: 0 20px 0 20px;
        @media only screen and (max-width: $r-768) {
          order: 3; } }
      .info {
        order: 3;
        padding-left: 35px;
        padding-right: 0;
        border-right: 0;
        border-left: 1px solid rgba(31, 59, 85, 0.3);
        @media only screen and (max-width: $r-768) {
          order: 1;
          padding-left: 0;
          border-left: 0; } }
      .content {
        order: 2;
        padding: 0 60px 0 0;
        @media only screen and (max-width: $r-768) {
          order: 2;
          padding-right: 0; } } }
    .download {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 54px;
      min-width: 330px;
      background: #00E08F;
      border-radius: 20px 0 20px 0;
      @media only screen and (max-width: $r-768) {
        height: 46px;
        max-width: 275px;
        min-width: auto;
        width: 100%; }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 154.2%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFF;
        @media only screen and (max-width: $r-768) {
          font-size: 13px; }
        svg {
          margin-left: 9px; } } }
    .info {
      width: 380px;
      border-right: 1px solid rgba(31, 59, 85, 0.3);
      padding-right: 35px;
      @media only screen and (max-width: $r-768) {
        width: 100%;
        padding-right: 0;
        border-right: 0;
        border-bottom: 1px solid rgba(31, 59, 85, 0.3); }
      .text {
        position: relative;
        p {
          max-width: 280px;
          margin: 0 auto 10px auto;
          font-style: italic;
          font-weight: bold;
          font-size: 21px;
          line-height: 166.2%;
          text-align: center;
          color: #0D142E;
          @media only screen and (max-width: $r-768) {
            max-width: 260px;
            font-size: 19px; } }
        svg {
          position: absolute;
          left: -20px;
          top: -15px;
          @media only screen and (max-width: $r-768) {
            left: -7px;
            width: 40px; } } }
      .avatar {
        margin-bottom: 15px;
        text-align: center;
        img {
          height: 130px;
          @media only screen and (max-width: $r-768) {
            height: 125px; } } }
      .about {
        max-width: 302px;
        text-align: center;
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 166.2%;
          text-align: center;
          margin-bottom: 30px;
          color: #000; }
        b {
          display: block;
          font-weight: 700; } }
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 250px;
        height: 110px;
        margin: 0 auto -95px auto;
        background: #FFF;
        border: 1px solid #FAFAFA;
        box-shadow: 0 7px 45px rgba(89, 151, 223, 0.17);
        border-radius: 15px;
        position: relative;
        top: 0;
        transition: .3s;
        @media only screen and (max-width: $r-768) {
          margin-bottom: 32px; }
        img {
          max-width: 80%;
          max-height: 90px; } } }
    .content {
      width: calc(100% - 380px);
      padding: 0 0 0 60px;
      @media only screen and (max-width: $r-768) {
        padding: 22px 0 0 0;
        width: 100%; }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #359CF9;
        @media only screen and (max-width: $r-768) {
          font-size: 21px; } }
      p {
        max-width: 675px;
        b,
        strong {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          margin-right: 10px;
          color: #714AFF;
          @media only screen and (max-width: $r-768) {
            display: block;
            font-size: 21px;
            margin-bottom: 5px; } } } } } }

// Stories get

.stories-get {
  @media only screen and (max-width: $r-768) {
    padding: 65px 0 100px 0; }
  .bg {
    bottom: -45%; }
  .bg-mobile {
    top: 375px; }
  .wrapper {
    img {
      max-width: 500px;
      @media only screen and (max-width: $r-768) {
        max-width: 100%; } } } }
