// Partners hero

.partners-hero {
  padding: 100px 0 15px 0;
  margin-bottom: 85px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  @media only screen and (max-width: $r-1200) {
    background-position: bottom; }
  @media only screen and (max-width: $r-768) {
    background-image: url("../img/bg-partenrs-mobile.svg") !important;
    background-size: cover;
    padding-top: 88px; }
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 58px;
    line-height: 141.4%;
    text-transform: capitalize;
    margin-bottom: 40px;
    color: #0D142E;
    @media only screen and (max-width: $r-768) {
      font-size: 36px;
      line-height: 50.9px; } }
  img {
    max-width: 480px;
    @media only screen and (max-width: $r-768) {
      max-width: 100%; } } }

// Partners text

.partners-text {
  display: none;
  margin-top: -15px;
  padding-bottom: 115px;
  @media only screen and (max-width: $r-768) {
    background: linear-gradient(91.54deg, #2397FE 6.46%, #5BADF9 103.19%);
    padding: 25px 0;
    margin: -40px 0 85px 0; }
  .item {
    background: linear-gradient(91.54deg, #2397FE 6.46%, #5BADF9 103.19%);
    padding: 45px 0 30px 0;
    @media only screen and (max-width: $r-768) {
      background: initial;
      padding: 0; }
    p {
      max-width: 900px;
      margin: 0 auto 16px auto;
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 176.4%;
      text-align: center;
      color: white;
      @media only screen and (max-width: $r-768) {
        font-size: 15px;
        line-height: 26.46px; } } } }

// Partners list

.partners-list {
  padding: 50px 0;
  position: relative;
  .shape {
    position: absolute;
    bottom: -14%;
    left: 0;
    width: 100%;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: none; }
    img {
      width: 100%; } }
  .shape-mobile {
    display: none;
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 100%;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      display: block; }
    img {
      width: 100%; } }
  .item {
    min-height: 345px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #FAFAFA;
    background-color: white;
    box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
    border-radius: 20px;
    padding: 74px 30px 45px 35px;
    margin-bottom: 100px;
    position: relative;
    cursor: pointer;
    @media only screen and (max-width: $r-768) {
      min-height: 250px;
      padding: 60px 30px 35px 30px; }
    &:hover {
      transition: .3s;
      .logo {
        top: -10px; } }
    .logo {
      position: relative;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -130px 0 36px 0;
      background-color: white;
      width: 193px;
      height: 95px;
      border: 1px solid #FAFAFA;
      box-shadow: 0 7px 45px rgba(89, 151, 223, 0.17);
      border-radius: 15px;
      transition: .3s;
      @media only screen and (max-width: $r-768) {
        width: 170px;
        height: 83px;
        margin-top: -115px; } }
    h3 {
      width: 100%;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 10px;
      text-align: left;
      color: #359CF9;
      @media only screen and (max-width: $r-768) {
        font-size: 20px;
        line-height: 24.38px; } }
    p {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 166.2%;
      color: rgba(0, 0, 0, 0.8);
      @media only screen and (max-width: $r-768) {
        font-size: 13px;
        line-height: 21.61px; } }
    .category {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 200px;
      height: 43px;
      background: #FF017B;
      border-radius: 0 20px 0 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 154.2%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: white;
      @media only screen and (max-width: $r-768) {
        width: 175px;
        height: 37px;
        font-size: 13px; } } } }

// Partners modal

.partners-modal {
  .modal-dialog {
    max-width: 740px; }
  .modal-content {
    border: 1px solid #FAFAFA;
    box-shadow: 0 7px 50px rgba(89, 151, 223, 0.2);
    border-radius: 20px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      right: 0;
      background-image: url("../img/bg-modal.svg");
      background-size: cover;
      border-radius: 20px;
      width: 40%;
      height: 73%; }
    .modal-body {
      padding: 85px 65px 35px 65px;
      @media only screen and (max-width: $r-768) {
        padding: 45px 25px 20px 25px; } }
    .close-modal {
      position: absolute;
      right: 23px;
      top: 12px;
      z-index: 1;
      cursor: pointer;
      @media only screen and (max-width: $r-768) {
        right: 10px;
        top: 27px; }
      &:hover {
        transition: .3s;
        .arrows {
          fill: $color-violet; } }
      svg {
        path {
          transition: .3s; } } }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -130px 0 40px 0;
      width: 197px;
      height: 96px;
      background: white;
      border: 1px solid #FAFAFA;
      box-shadow: 0 7px 45px rgba(89, 151, 223, 0.17);
      border-radius: 15px;
      @media only screen and (max-width: $r-768) {
        margin: -115px auto 25px auto; } }
    .flex {
      max-width: 600px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: $r-768) {
        flex-direction: column;
        align-items: flex-start; }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 23px;
        color: #359CF9;
        @media only screen and (max-width: $r-768) {
          margin-bottom: 11px;
          order: 2; } } }
    a {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 170.2%;
      text-decoration-line: underline;
      color: #714AFF;
      @media only screen and (max-width: $r-768) {
        order: 1;
        margin-bottom: 20px;
        font-size: 14px; }
      &:hover {
        transition: .3s;
        svg {
          left: 5px; } }
      svg {
        margin-left: 10px;
        position: relative;
        left: 0;
        transition: .3s; } }
    p {
      max-width: 600px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 170.2%;
      color: rgba(0, 0, 0, 0.8);
      @media only screen and (max-width: $r-768) {
        font-size: 13px;
        line-height: 22.13px; } } } }

