// Blog hero

.blog-hero {
  padding: 100px 0 15px 0;
  margin-bottom: 85px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  @media only screen and (max-width: $r-1200) {
    background-position: bottom; }
  @media only screen and (max-width: $r-768) {
    background-image: url("../img/bg-blog-mobile.svg") !important;
    background-size: cover;
    padding: 88px 0 55px 0;
    margin-bottom: 78px; }
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 58px;
    line-height: 141.4%;
    text-transform: capitalize;
    margin-bottom: 40px;
    color: #0D142E;
    @media only screen and (max-width: $r-768) {
      font-size: 36px;
      line-height: 50.9px;
      margin-bottom: 140px; } }
  img {
    max-width: 480px;
    @media only screen and (max-width: $r-768) {
      max-width: 100%; } } }

// Blog list

.blog-list {
  padding: 10px 0;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 0; }
  .shape {
    position: absolute;
    left: 0;
    top: 90px;
    width: 100%;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      top: 120px; }
    img {
      &:first-of-type {
        @media only screen and (max-width: $r-768) {
          display: none; } }
      &:nth-of-type(2) {
        width: 100%;
        display: none;
        @media only screen and (max-width: $r-768) {
          display: block; } } } }
  .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 36px; }
    &:hover {
      text-decoration: none; }
    .image {
      margin-bottom: 22px;
      img {
        height: 240px;
        object-fit: cover;
        border-radius: 14px;
        @media only screen and (max-width: $r-768) {
          height: 214px; } } }
    .info {
      .flex {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .category {
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 12px;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-right: 14px;
          color: #4F6AFF; }
        .read {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 12px;
          letter-spacing: 0.5px;
          color: rgba(18, 18, 18, 0.5); } }
      h3 {
        min-height: 60px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #0D142E;
        @media only screen and (max-width: $r-768) {
          font-size: 18px;
          line-height: 26px; } } }
    &.large-item {
      margin-bottom: 120px;
      flex-direction: row;
      flex-wrap: wrap;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 84px; }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        background: #EFF9FF;
        border-radius: 24px 0 0 24px;
        padding: 15px 74px 15px 74px;
        @media only screen and (max-width: $r-768) {
          order: 2;
          width: 100%;
          padding: 35px 25px 45px 25px;
          border-radius: 0 0 24px 24px; }
        h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 40px;
          line-height: 49px;
          margin-bottom: 28px;
          color: #000;
          @media only screen and (max-width: $r-768) {
            font-size: 34px;
            line-height: 41px;
            margin-bottom: 25px; } }
        .text {
          margin-bottom: 75px;
          @media only screen and (max-width: $r-768) {
            margin-bottom: 24px; }
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: rgba(13, 20, 46, 0.8);
            @media only screen and (max-width: $r-768) {
              font-size: 14px; } } }
        .flex {
          .read {
            font-size: 15px; } } }
      .image {
        width: 50%;
        margin-bottom: 0;
        @media only screen and (max-width: $r-768) {
          order: 1;
          width: 100%; }
        img {
          min-height: 593px;
          border-radius: 0 24px 24px 0;
          @media only screen and (max-width: $r-768) {
            min-height: 289px;
            border-radius: 24px 24px 0 0; } } }
      .more {
        text-align: right;
        width: 100%;
        margin-top: 28px;
        @media only screen and (max-width: $r-768) {
          order: 3;
          text-align: center; }
        .btn-green {
          display: inline-flex;
          height: 46px;
          min-width: 165px;
          svg {
            margin-left: 7px; } } } } }
  .wp-pagenavi {
    display: flex;
    justify-content: flex-end;
    margin-top: -40px;
    a,
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      border: 0;
      margin: 0 5px;
      padding: 0;
      width: 27px;
      height: 27px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #0D142E;
      &.pages {
        display: none; }
      &:hover {
        border: 0; }
      &.current {
        font-weight: bold;
        color: #3EA2FB; }
      &.nextpostslink,
      &.previouspostslink {
        position: relative;
        &:before {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background-image: url("../img/icon-pagination.svg");
          background-size: 6px;
          background-repeat: no-repeat;
          background-position: center; } }
      &.previouspostslink {
        &:before {
          transform: rotate(180deg); } } } } }

.paged {
  .blog-list {
    .hide-paged {
      display: none; } } }

// Blog get

.blog-get {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 145px 0 120px 0;
  margin-bottom: -100px;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding: 78px 0 43px 0;
    margin-bottom: 60px; }
  .title-secondary {
    margin-bottom: 22px;
    @media only screen and (max-width: $r-768) {
      max-width: 180px;
      margin: 0 auto 25px auto; } }
  p {
    max-width: 535px;
    margin: 0 auto 16px auto;
    @media only screen and (max-width: $r-768) {
      max-width: 285px; }
    &:last-of-type {
      margin-bottom: 45px;
      @media only screen and (max-width: $r-768) {
        margin-bottom: 33px; } } }
  .shape {
    position: absolute;
    @media only screen and (max-width: $r-768) {
      display: none; } }
  .shape-mobile {
    display: none;
    position: absolute;
    width: 100%;
    img {
      width: 100%; }
    @media only screen and (max-width: $r-768) {
      display: block; } } }

// Blog single

.blog-single {
  padding: 130px 0;
  position: relative;
  @media only screen and (max-width: $r-768) {
    padding-bottom: 80px; }
  .shape {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    img {
      &:first-of-type {
        @media only screen and (max-width: $r-768) {
          display: none; } }
      &:nth-of-type(2) {
        display: none;
        @media only screen and (max-width: $r-768) {
          display: block; } } } }
  .content {
    max-width: 755px;
    padding: 0;
    margin: 0 auto;
    .image {
      margin-bottom: 22px;
      img {
        border-radius: 14px;
        margin-bottom: 0;
        @media only screen and (max-width: $r-768) {
          height: 230px;
          object-fit: cover; } } }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 35px;
      @media only screen and (max-width: $r-768) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 44px; }
      .author {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 12px;
        letter-spacing: 0.5px;
        color: rgba(18, 18, 18, 0.7);
        @media only screen and (max-width: $r-768) {
          font-size: 13px;
          margin-bottom: 27px; } }
      .socials {
        width: 50%;
        @media only screen and (max-width: $r-768) {
          width: 100%; }
        .a2a_kit {
          display: flex;
          justify-content: flex-end;
          line-height: initial;
          @media only screen and (max-width: $r-768) {
            width: 100%;
            justify-content: center; }
          a {
            display: flex;
            height: 50px;
            padding: 0;
            img {
              margin: 0;
              height: 100%;
              opacity: 1;
              @media only screen and (max-width: $r-768) {
                height: 60px; } } } } } }
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 50px;
      text-transform: capitalize;
      margin-bottom: 36px;
      color: #0D142E;
      @media only screen and (max-width: $r-768) {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 18px; } }
    h2 {
      font-size: 26px;
      line-height: 28px;
      margin: 55px 0 27px 0;
      @media only screen and (max-width: $r-768) {
        margin: 42px 0 28px 0; } }
    h3 {
      font-size: 18px;
      line-height: 28px;
      margin: 42px 0 17px 0; }
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;
      color: #0D142E; }
    p,
    ul li,
    ol li {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: #0D142E;
      @media only screen and (max-width: $r-768) {
        font-size: 14px;
        line-height: 24px; } }
    p {
      margin-bottom: 24px; }
    ol {
      padding-left: 20px;
      margin-bottom: 30px;
      li {
        margin-bottom: 15px;
        padding-right: 32px; } }
    ul {
      padding-left: 20px;
      margin-bottom: 30px;
      li {
        margin-bottom: 15px;
        padding-right: 32px;
        position: relative;
        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 8px;
          width: 9px;
          height: 9px;
          background: #8854DD;
          border-radius: 50px; } } }
    img {
      margin: 25px 0; }
    blockquote {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 580px;
      max-width: 100%;
      background-color: white;
      box-shadow: 0 18px 42.85px rgba(215, 228, 249, 0.45);
      border-radius: 20px;
      margin: 0 auto 30px auto;
      padding: 63px 70px;
      position: relative;
      z-index: 1;
      @media only screen and (max-width: $r-768) {
        padding: 50px 40px; }
      &:before {
        position: absolute;
        content: '';
        background-image: url("../img/icon-quotes.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 186px;
        height: 133px;
        z-index: -1;
        @media only screen and (max-width: $r-768) {
          width: 99px;
          height: 80px;
          top: 25px; } }
      //&:after
      //  position: absolute
      //  content: ''
      //  top: -16px
      //  right: -20px
      //  width: 100%
      //  height: 100%
      //  background-color: white
      //  box-shadow: 0 18px 52.8537px rgba(215, 228, 249, 0.5)
      //  border-radius: 20px
      //  z-index: -2
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 58px;
        letter-spacing: 0.01em;
        margin: 0;
        text-align: center;
        color: #0D142E;
        @media only screen and (max-width: $r-768) {
          font-size: 34px;
          line-height: 44px; } } } } }

// Blog related

.blog-related {
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
  .shape {
    position: absolute;
    top: 140px; }
  .title-secondary {
    text-align: center;
    margin-bottom: 57px;
    @media only screen and (max-width: $r-768) {
      margin-bottom: 33px; } }
  .wrapper {
    .item {
      margin-bottom: 0; } }
  .swiper-related {
    padding-bottom: 75px;
    .arrows {
      display: flex;
      justify-content: center;
      margin-top: -30px;
      .swiper-button-next,
      .swiper-button-prev {
        background-image: none;
        margin: 0;
        position: initial;
        width: 75px;
        height: 75px;
        outline: none; } } } }
