// Header

.header {
  width: 100%;
  padding: 25px 0;
  position: fixed;
  transition: .3s;
  background-color: transparent;
  z-index: 2;
  @media only screen and (max-width: $r-992) {
    padding: 21px 0; }
  &.sticky {
    background-color: white;
    transition: .3s; }
  .flex {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  nav {
    ul {
      display: flex;
      margin-bottom: 0;
      li {
        margin: 0 15px;
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 156.4%;
          color: #0D142E; } } } }
  .cta {
    margin-left: 40px; }
  .nav-mobile {
    width: 100%;
    display: none;
    background-color: white;
    box-shadow: 0 23px 40px rgba(89, 151, 223, 0.2);
    border-radius: 0 0 90px 20px;
    padding: 30px 0 50px 0;
    margin-top: 21px;
    &.active {
      display: block; }
    nav {
      ul {
        flex-direction: column;
        li {
          padding: 10px 15px 10px 60px;
          margin: 0;
          position: relative;
          &.current-menu-item {
            a {
              color: #1D99FF; }
            &:before {
              position: absolute;
              content: '';
              width: 5px;
              height: 100%;
              left: 0;
              top: 0;
              background: #1D99FF;
              border-radius: 5px; } }
          a {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #0D142E; } } } }
    .cta {
      margin: 20px 0 0 60px; } } }
