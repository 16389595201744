// Fonts

$color-green: #00E08F;
$color-violet: #714AFF;

body {
  font-family: 'Montserrat', sans-serif!important;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 485px;
    max-width: 100%;
    height: 293px;
    background-image: url("../img/shape-body.svg");
    background-size: cover;
    z-index: -1;
    @media only screen and (max-width: $r-768) {
      background-image: none!important; } }
  .container {
    max-width: 1170px; }
  .modal-backdrop {
    background: rgba(31, 55, 69, 0.4);
    &.show {
      opacity: 1; } } }

// Global

.show-desctop {
  @media only screen and (max-width: $r-768) {
    display: none; } }

.show-mobile {
  display: none;
  @media only screen and (max-width: $r-768) {
    display: block; } }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 156.4%;
  color: #444C6A;
  @media only screen and (max-width: $r-768) {
    font-size: 14px;
    line-height: 21.9px; }
  b,
  strong {
    font-weight: 600; } }

img {
  max-width: 100%;
  height: auto; }

.title-secondary {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  text-transform: capitalize;
  color: #0D142E;
  @media only screen and (max-width: $r-768) {
    font-size: 29px;
    line-height: 35.35px; } }

.title-third {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  color: #0D142E;
  @media only screen and (max-width: $r-768) {
    font-size: 29px;
    line-height: 35.35px; } }


.btn-green {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 48px;
  background-color: $color-green;
  border-radius: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 156.4%;
  transition: .3s;
  color: white;
  &:hover {
    transition: .3s;
    background-color: #01C880;
    text-decoration: none;
    color: white; } }

.form-demo {
  form {
    display: flex;
    max-width: 380px;
    height: 43px;
    border: 1px solid #ACBDD4;
    box-sizing: border-box;
    border-radius: 30px;
    input[type="email"] {
      width: calc(100% - 155px);
      height: 41px;
      border-radius: 30px;
      border: 0;
      outline: 0;
      appearance: none;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 156.4%;
      padding-left: 24px;
      color: #3E2E64;
      &::placeholder {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 156.4%;
        color: #3E2E64; } }
    input[type="submit"] {
      width: 155px;
      height: 43px;
      border-radius: 0 30px 30px 0;
      border: 0;
      outline: 0;
      margin-top: -1px;
      position: relative;
      left: 1px;
      appearance: none;
      background-color: $color-green;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 156.4%;
      transition: .3s;
      color: white;
      &:hover {
        transition: .3s;
        background-color: #01C880;
        color: white; } } } }

// Content

.content {
  padding: 200px 0;
  ul,
  ol {
    padding: 0;
    margin-left: 0;
    li {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 156.4%;
      color: #444C6A;
      @media only screen and (max-width: $r-768) {
        font-size: 14px;
        line-height: 21.9px; }
      b,
      strong {
        font-weight: 600; } } }
  ul {
    li {
      position: relative;
      padding-left: 20px;
      &:before {
        position: absolute;
        left: 0;
        content: "•";
        font-weight: bold; } } }
  ol {
    counter-reset: item;
    li {
      &:before {
        content: counter(item) ". ";
        counter-increment: item;
        font-weight: bold; } } }
  h1, h2, h3, h4, h5, h6 {
    color: #0d142e; } }


// Scroll bar

::-webkit-scrollbar {
  width: 25px; }

::-webkit-scrollbar-track {
  background-color: white; }

::-webkit-scrollbar-thumb:vertical {
  -webkit-border-radius: 30px;
  border-radius: 30px;
  background: -webkit-linear-gradient(#5FAEF9,#2096FE); }
