// Footer

.footer {
  background-position: top;
  background-size: cover;
  padding: 140px 0 50px 0;
  @media only screen and (max-width: $r-768) {
    background-image: url("../img/bg-footer-mobile.svg") !important;
    padding-top: 130px; }
  .logo {
    text-align: right;
    margin-bottom: 90px;
    @media only screen and (max-width: $r-768) {
      text-align: center;
      margin-bottom: 75px;
      img {
        max-height: 45px; } } }
  .wrapper {
    align-items: flex-end;
    @media only screen and (max-width: $r-768) {
      flex-direction: column;
      div[class*="col-"] {
        &:first-of-type {
          order: 2; }
        &:nth-of-type(2) {
          order: 1; } } }
    .avocadostories {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 135%;
      letter-spacing: 0.015em;
      margin-bottom: 25px;
      color: white;
      @media only screen and (max-width: $r-768) {
        justify-content: center; }
      svg {
        margin-left: 5px; } }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 0;
      color: white;
      @media only screen and (max-width: $r-768) {
        text-align: center; } }
    nav {
      ul {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
        @media only screen and (max-width: $r-768) {
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 24px; }
        li {
          margin-left: 53px;
          @media only screen and (max-width: $r-992) {
            margin-left: 25px; }
          @media only screen and (max-width: $r-768) {
            margin: 0 15px 28px 15px!important; }
          &:first-of-type {
            margin-left: 0; }
          a {
            font-style: normal;
            font-weight: 500;
            font-size: 19px;
            line-height: 23px;
            color: white;
            @media only screen and (max-width: $r-992) {
              font-size: 14px; }
            @media only screen and (max-width: $r-768) {
              font-size: 21px;
              line-height: 25.6px; } } } } } } }
